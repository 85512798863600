<template>
  <svg
    viewBox="0 0 54 74"
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="74"
  >
    <path
      d="M27.0002 53.6666C25.1592 53.6666 23.6668 55.159 23.6668 56.9999C23.6668 58.8409 25.1592 60.3332 27.0002 60.3332H27.0335C28.8744 60.3332 30.3668 58.8409 30.3668 56.9999C30.3668 55.159 28.8744 53.6666 27.0335 53.6666H27.0002Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3335 0.333252C4.81065 0.333252 0.333496 4.8104 0.333496 10.3333V63.6666C0.333496 69.1894 4.81065 73.6666 10.3335 73.6666H43.6668C49.1897 73.6666 53.6668 69.1894 53.6668 63.6666V10.3333C53.6668 4.8104 49.1897 0.333252 43.6668 0.333252H10.3335ZM7.00016 10.3333C7.00016 8.4923 8.49255 6.99992 10.3335 6.99992H43.6668C45.5078 6.99992 47.0002 8.4923 47.0002 10.3333V63.6666C47.0002 65.5075 45.5078 66.9999 43.6668 66.9999H10.3335C8.49255 66.9999 7.00016 65.5075 7.00016 63.6666V10.3333Z"
    />
  </svg>
</template>
